import { render, staticRenderFns } from "./speakrs.vue?vue&type=template&id=0c05538f&"
import script from "./speakrs.vue?vue&type=script&lang=js&"
export * from "./speakrs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports